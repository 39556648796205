import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <SEO title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        { page.acf.contentBlocks && page.acf.contentBlocks.map((el, i) => RenderBlock(el.fieldGroupName, el, i))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          ... on WpPage_Acf_ContentBlocks_ScrollingTextBanner {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              altText
            }
            video
            mobileVideo
            text
          }
          ... on WpPage_Acf_ContentBlocks_Banner {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              altText
            }
            text
          }
          ... on WpPage_Acf_ContentBlocks_TitleContent {
            fieldGroupName
            title
            content
          }
          ... on WpPage_Acf_ContentBlocks_ImageTitleContent {
            fieldGroupName
            hashId
            images {
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            content
            reverse
          }
          ... on WpPage_Acf_ContentBlocks_Contact {
            fieldGroupName
            title
            content
            googleMap {
              latitude
              longitude
            }
          }
          ... on WpPage_Acf_ContentBlocks_BasicPage {
            fieldGroupName
            bannerImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            content
          }
          ... on WpPage_Acf_ContentBlocks_FeaturedProjects {
            fieldGroupName
            projects {
              __typename
              ... on WpProject {
                title
                slug
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
                acf {
                  suburb
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Gallery {
            fieldGroupName
            images {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              caption
            }
          }
          ... on WpPage_Acf_ContentBlocks_Vimeo {
            fieldGroupName
            video
          }
          ... on WpPage_Acf_ContentBlocks_Youtube {
            fieldGroupName
            video
          }
          ... on WpPage_Acf_ContentBlocks_Team {
            fieldGroupName
            title
            team {
              __typename
              ... on WpTeam {
                title
                content
                slug
                acf {
                  profileImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  position
                  email {
                    text
                    link
                  }
                  phone {
                    text
                    link
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_ArchTextBanner {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              altText
            }
            title
          }
        }
      }
    }
  }
`

export default PageTemplate
